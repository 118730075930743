<template>
    <div>
        <el-dialog title="编辑角色" :visible.sync="dialogVisible" width="482px" :before-close="handleClose">
            <div class="item">
                <div class="itemTitle">角色名称</div>
                <el-input :disabled="true" v-model="name" />
            </div>
            <div class="item">
                <div class="itemTitle">角色描述</div>
                <el-input v-model="desc" clearable />
            </div>
            <div class="item">
                <div class="itemTitle">角色成员</div>
                <el-select v-model="value" multiple filterable default-first-option placeholder="请选择"
                    :popper-append-to-body="false">
                    <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="ok">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getAll, changeApsRole } from '../../../api/setting.js'
export default {
    name: 'role_edit',
    data() {
        return {
            name: '',
            desc: '',
            options: [],
            value: [],
            filterArr: [],
            temp: null
        }
    },
    props: {
        dialogVisible: {
            type: Boolean,
        },
        roleName: {
            type: String
        },
        roleDesc: {
            type: String
        },
        roleMember: {
            type: Array
        },
        roleInfo: {
            type: Object
        },
        roleArr: {
            type: Array,
            defalut: []
        }
    },
    watch: {
        roleArr() {
            this.value = this.roleArr
        },
        dialogVisible() {
            this.getAllFn()
        },
        roleName() {
            this.name = this.roleName
        },
        roleDesc() {
            this.desc = this.roleDesc
        },
        value() {
            this.valFn()
        }
    },
    methods: {
        async getAllFn() {
            let orgId = 2  // 后端约定，暂时为2
            const res = await getAll(orgId)
            if (res.code == 0) {
                this.options = []
                res.data.forEach((v, i) => {
                    this.options.push({
                        lable: i,
                        value: v.username,
                        id: v.userId
                    })
                })
            }
        },
        handleClose() {
            this.options = []
            this.value = []
            this.$emit('editClose')
        },
        async ok() {
            if (this.filterArr.length === 0) this.valFn()
            const { id, roleName } = this.roleInfo
            const res = await changeApsRole({
                id: Number(id),
                roleName,
                roleDesc: this.desc,
                userInfos: this.filterArr
            })
            if (res.code == 0) {
                this.$message.success('操作成功！')
            } else if (res.code != 0) {
                this.$message.error(res.msg)
            }
            this.$emit('editOk')
            this.value = []
        },
        valFn() {
            if (this.options.length > 0) {
                this.filterArr = []
                for (let i = 0; i < this.value.length; i++) {
                    if (this.value[i] === this.options.filter(v => v.value === this.value[i])[0].value) {
                        this.filterArr.push({
                            userId: this.options.filter(v => v.value === this.value[i])[0].id,
                            username: this.options.filter(v => v.value === this.value[i])[0].value
                        })
                    }
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep {
    .el-dialog__wrapper {
        text-align: left;
    }

    .el-dialog {
        padding: 10px 23px;
        border-radius: 12px;
        background-color: #232323;
    }

    .el-dialog__title {
        font-size: 24px;
    }

    .el-dialog__body {
        padding-top: 10px;
    }

    .el-dialog .el-dialog__headerbtn .el-dialog__close {
        font-size: 24px;
    }

    .el-input.is-disabled .el-input__inner {
        background: #444444;
    }

    .el-input__inner {
        border-radius: 6px;
        font-size: 16px;
        color: #fff;
    }

    .el-button:nth-child(1) {
        background-color: transparent;
        border: 1px solid #8A8A8A;
    }

    .el-button {
        font-size: 16px;
        height: 40px;
        width: 90px;
        padding: 0 !important;
    }

    .el-select {
        width: 100%;
    }

    .el-tag {
        color: #fff;
        font-size: 16px;
        background-color: #464646;
        border: 1px solid #727272;
        padding: 4px 13px;
        height: 30px;

        span {
            margin-right: 6px;
        }
    }

    .el-tag__close {
        background-color: transparent !important;
    }

    .el-tag .el-icon-close {
        font-size: 21px;
        color: #B9B9B9;
    }

    .el-select__tags {
        height: max-content !important;
    }

    .el-select__input {
        color: #fff;
        font-size: 16px;
    }

    // .el-select-dropdown{
    //     background-color: #343434 !important;
    //     border-radius: 6px !important;
    // }
}

.item {
    margin-bottom: 12px;
    position: relative;

    .itemTitle {
        font-size: 16px;
        color: #E8E8E8;
    }
}

::v-deep .el-select-dropdown {
    background-color: #343434 !important;
}

::v-deep {
    .el-select-dropdown__item {
        background-color: transparent;
        margin: 0 10px;
        border-radius: 4px;
        margin-bottom: 3px;
    }

    .el-select-dropdown__item.selected {
        background-color: #4B4B4B !important;
        color: #2DEB79;
        font-size: 16px;
    }

    .el-select-dropdown__item hover {
        background-color: #4B4B4B;
        color: #2DEB79;
    }

    .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
        font-size: 18px;
    }

    .el-dialog__footer {
        padding-top: 0;
    }
}
</style>