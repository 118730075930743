import { get, post } from "../utils/http"
import { baseUrls } from '../utils/urls'
const aps2 = baseUrls.aps2
// 角色信息分页查询
export function getApsRole(page, size) {
    return get(`${aps2}/admin/apsRole/page?page=${page}&size=${size}`)
}
// 搜索
export function searchApsRole(page, size, searchValue) {
    return get(`${aps2}/admin/apsRole/page?page=${page}&size=${size}&searchValue=${searchValue}`)
}
// 修改角色
export function changeApsRole(params) {
    return post(`${aps2}/admin/apsRole/updateById`, params)
}
// 获取所有用户
export function getAll(orgId) {
    return get(`${aps2}/admin/apsUser/taskmanList?orgId=${orgId}`)
}